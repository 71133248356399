import React, { useEffect, useState } from "react";
import "./HeroPage.scss";

export const HeroPage = React.forwardRef<HTMLDivElement>((_, ref) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!isScrolled) {
        setIsScrolled(true);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);
  return (
    <>
      <div className="hero-page" ref={ref}>
        <div className="mobile-section">
          <div className="mobile-section-image"></div>
        </div>
        <div className="main-content">
          <div className="content">
            <div className="text-container">
              <h1>Kelley Reynolds</h1>
              <h2>Communications Specialist</h2>
              <p>
                Hi, I'm Kelley! I am an American living in Denmark. I use my
                communication expertise to help companies establish internal
                communication processes, develop communication strategies,
                create digital content, and hone their branding. I work
                strategically and holistically across all relevant channels to
                reach the desired target audiences. I am always looking for new
                challenges that further my professional development.
              </p>
              <p>
                On a personal level, I am optimistic, goal-oriented, empathetic,
                and often smiling. In my free time, I sing Alto in a community
                choir, host board game nights, and love to travel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
