import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import { HeroPage } from "./HeroPage/HeroPage";
import { ArticlePage } from "./ArticlePage/ArticlePage";
import arrow from "./HeroPage/expand.svg";
function App() {
  const heroPageRef = useRef<HTMLDivElement>(null);
  const arrowSectionRef = useRef<HTMLDivElement>(null);
  const articlePageRef = useRef<HTMLDivElement>(null);
  const [activeNavItem, setActiveNavItem] = useState<"about" | "articles">(
    "about"
  );
  useEffect(() => {
    const handleScroll = (e: any) => {
      const newActiveNavItem =
        articlePageRef.current &&
        articlePageRef.current.getBoundingClientRect().top < 0
          ? "articles"
          : "about";
      if (activeNavItem !== newActiveNavItem) {
        setActiveNavItem(newActiveNavItem);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeNavItem]);

  return (
    <Router>
      <div className="app">
        <div className="header">
          <nav className="navigation">
            <button
              className={activeNavItem === "about" ? "active" : ""}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              About me
            </button>
            <button
              className={activeNavItem === "articles" ? "active" : ""}
              onClick={() => {
                window.scrollTo(
                  0,
                  (heroPageRef.current?.getBoundingClientRect().height || 0) +
                    (arrowSectionRef.current?.getBoundingClientRect().height ||
                      0) +
                    1 || 0
                );
              }}
            >
              My work
            </button>
          </nav>
        </div>
        <HeroPage ref={heroPageRef} />
        <div className="arrow-section" ref={arrowSectionRef}>
          <img
            className="arrow-icon"
            src={arrow}
            alt="scroll-indicator"
            onClick={() => {
              window.scrollTo(
                0,
                (heroPageRef.current?.getBoundingClientRect().height || 0) +
                  (arrowSectionRef.current?.getBoundingClientRect().height ||
                    0) +
                  1 || 0
              );
            }}
          />
        </div>
        <ArticlePage ref={articlePageRef} />
        <div className="photo-link-container">
          {/*<a
            href="https://louiseneupert.studio/?fbclid=IwAR2O8GnPJTjjDPMdMrcROXfDp4AkVBiSyrb9uEKatxJKzBmimos8mcI5ZMU"
            className="photo-link"
            target="_blank"
            rel="noreferrer"
          >
            Photo credit: Louise Neupert
          </a>*/}
        </div>
      </div>
    </Router>
  );
}

export default App;
